"use strict";

var _interopRequireDefault = require("/build-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find-index");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
var _defineProperty2 = _interopRequireDefault(require("/build-app/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _WorkOrderEntity = _interopRequireDefault(require("@services/WorkOrderEntity"));
var _library = require("library");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      // 上传图片
      dialogImageUrl: "",
      imageList: [],
      fileList: [],
      dialogVisible: false,
      btnLoading: false,
      uploadUtil: {},
      // 上传时候需要附带的额外参数
      uploadData: {
        is_thumb: 1
      },
      innerVisible: false,
      ifBun: true,
      form: {
        treatment_group: "",
        handle_class_id: ""
      },
      formData: {
        image_mark: ""
      },
      rules: {
        treatment_group: [{
          required: true,
          message: this.$t("pleaseSelectFollowGroup"),
          trigger: "change"
        }]
      },
      wid: "",
      title: this.$t("markedAsPending"),
      groups: {
        1: this.$t("cwGroup"),
        2: this.$t("kfGroup"),
        3: this.$t("udGroup"),
        4: this.$t("fkGroup"),
        5: this.$t("testGroup"),
        6: this.$t("yhGroup"),
        7: this.$t("dxGroup"),
        8: this.$t("dfGroup"),
        9: this.$t("summaryAnyPart"),
        10: this.$t("tcdfGroup")
      },
      parentId: "",
      isDealPending: false,
      isMass: "",
      groupList: [],
      dealDocument: []
    };
  },
  created: function created() {
    this.uploadUtil = new _library.upload({
      env: process.env.NODE_ENV,
      model: "filer"
    });
  },
  mounted: function mounted() {},
  methods: {
    // isDealPending:表示是处理中模块页面点击了待确认
    open: function open(title, obj, data, isDealPending, isMass) {
      this.dealDocument = this.$store.state.groupManage.dealDocument;
      this.groupList = this.$store.state.groupManage.followUpGroup;
      this.btnLoading = false;
      this.parentId = obj.id;
      this.isDealPending = isDealPending;
      this.title = title;
      this.isMass = isMass;
      // this.wid = obj.id
      this.form = _objectSpread({}, obj);
      this.form.handle_class_id = '';
      if (data) {
        this.form = _objectSpread(_objectSpread(_objectSpread({}, obj), data), {}, {
          handle_class_id: data.handle_class_id > 0 ? Number(data.handle_class_id) : ''
        });
      }
      this.fileList = this.form.image_mark ? JSON.parse(this.form.image_mark) : [];
      this.imageList = this.fileList;
      this.innerVisible = true;
    },
    // 超出图片限制
    handleExceed: function handleExceed(files, fileList) {
      this.$message.error(this.$t("pictureLimit"));
    },
    // 上传之前判断
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var isJPG = file.type === "image/jpeg" || file.type === "image/png";
      if (!isJPG) {
        this.$message.error(this.$t("pictureTypeLimit"));
      }
      return isJPG;
    },
    innerCancel: function innerCancel() {
      this.btnLoading = false;
      this.innerVisible = false;
      this.form = {};
      this.$refs["form"].resetFields();
      this.imageList = [];
    },
    // 预览图片
    handlePictureCardPreview: function handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 图片操作
    handleRemove: function handleRemove(file, fileList) {
      this.fileList = this.fileList.filter(function (e) {
        return e !== file;
      });
      this.imageList = fileList;
    },
    // 上传成功handleSuccess
    handleSuccess: function handleSuccess(response, file, fileList) {
      file.url = file.response.data.domain + file.response.data.path;
      this.fileList.push(file);
      this.imageList = fileList;
    },
    // // 下一步
    // addFile(file) {
    //   this.$nextTick(() => {
    //   })
    // },
    innerConfirm: function innerConfirm() {
      var _this = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          _this.submit();
        }
      });
    },
    submit: function submit() {
      var _this2 = this;
      this.formData.image_mark = JSON.stringify(this.fileList.map(function (file) {
        return {
          name: file.name,
          url: file.url
        };
      }));
      // this.formData.workorder_file = this.form.image_mark;

      this.btnLoading = true;
      _WorkOrderEntity.default.updateOrderStatus(_objectSpread(_objectSpread(_objectSpread({}, this.form), this.formData), {}, {
        handle_class_id: this.form.handle_class_id || undefined
      })).then(function (res) {
        var code = res.code,
          msg = res.msg;
        if (code === 200) {
          _this2.innerCancel();
          _this2.$message.success(_this2.$t("markSuccess"));
          _this2.$emit("batchLabeled");
          // this.$store.dispatch('getNumber')
          _this2.$store.dispatch("getMessageNumber");
          // 判断是待确认并且点击的是模块处理中的待确认
          if (_this2.title == _this2.$t("markedAsPending") && _this2.isDealPending && _this2.isMass !== "mass") {
            var list = _this2.$parent.tableData;
            var currentIndex = list.findIndex(function (ele) {
              return ele.id == _this2.parentId;
            });
            _this2.$parent.tableData.splice(currentIndex, 1);
            _this2.$parent.freshList();
          } else {
            _this2.$parent.freshList();
          }
        } else {
          _this2.btnLoading = false;
          _this2.$message.error(msg);
        }
      }).catch(function () {
        _this2.btnLoading = false;
      });
    } // urge() {
    //   EntityService.urged({
    //     id: this.wid
    //   })
    //     .then((res) => {
    //       const { code } = res
    //       if (code === 200) {
    //         this.$message.success(this.$t('urgetohandleisSuccess'))
    //         this.$parent.getList()
    //         this.innerCancel()
    //       }
    //     })
    //     .catch()
    // }
  }
};