var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "dashboard-editor-container" }, [
      _vm._v("欢迎来到工单管理系统"),
    ]),
    _c(
      "div",
      { staticStyle: { "margin-top": "20px", "margin-left": "35px" } },
      [
        _c("div", { staticStyle: { "margin-bottom": "15px" } }, [
          _vm._v("\n      各位道友、仙子，感谢大家使用工单管理系统。\n    "),
        ]),
        _c("div", { staticStyle: { "margin-bottom": "15px" } }, [
          _vm._v(
            "\n      大家在工作的过程中可能会遇到各种奇怪的BUG，不合理的设定，甚至让人扔键盘的问题，\n    "
          ),
        ]),
        _c("div", { staticStyle: { "margin-bottom": "15px" } }, [
          _vm._v(
            "\n      在此真心请求大家多点耐心与包容，我们会全力去优化与改进系统。\n    "
          ),
        ]),
        _c("div", { staticStyle: { "margin-bottom": "15px" } }, [
          _vm._v(
            "\n      如果在使用过程中，有好的优化想法，请和我们提出；\n    "
          ),
        ]),
        _c(
          "div",
          { staticStyle: { "margin-bottom": "35px", "margin-top": "20px" } },
          [_vm._v("Tudou")]
        ),
        _c("el-divider"),
        _vm._m(0),
        _c("div", { staticClass: "help" }, [
          _c("div", { staticClass: "left" }, [
            _c("p", { staticClass: "font" }, [_vm._v("1.手机端搜索，如下图")]),
            _c("p", [
              _c("img", {
                staticClass: "img1",
                attrs: { src: _vm.getImg("help1"), alt: "" },
              }),
            ]),
          ]),
          _c("div", { staticClass: "right" }, [
            _c("p", { staticClass: "font" }, [
              _vm._v(
                "2.首次登录/重置之后 的页面，需要扫码或者输入密钥 绑定安全码;"
              ),
            ]),
            _c("p", [
              _c("img", {
                staticClass: "img4",
                attrs: { src: _vm.getImg4("help4"), alt: "" },
              }),
            ]),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hint" }, [
      _c("p", { staticClass: "tips1" }, [
        _vm._v(
          "\n        这边已经加入了谷歌二次认证，如果您登录之后发现需要二次认证，在首次绑定的时候\n      "
        ),
      ]),
      _c("p", { staticClass: "tips2" }, [
        _vm._v(
          "\n        强烈建议您备份好您的密钥。当你尝试绑定新的谷歌验证器时，密钥是必不可少的。\n      "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }