"use strict";

var _interopRequireDefault = require("/build-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _toConsumableArray2 = _interopRequireDefault(require("/build-app/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _WorkOrderQues = _interopRequireDefault(require("@services/WorkOrderQues"));
var _WorkOrderEntity = _interopRequireDefault(require("@services/WorkOrderEntity"));
var _siteAdmin = _interopRequireDefault(require("@services/siteAdmin"));
var _utility = require("@/views/system/account-manage/utility");
var _store = _interopRequireDefault(require("../../store"));
var _elementUi = require("element-ui");
var _vueI18n = _interopRequireDefault(require("vue-i18n"));
var activity = {
  state: {
    // 导航菜单
    navMenuList: [],
    sysPanelCreateArr: [],
    // 索引数组
    sysPanelListIndexArr: [],
    // 文件数组索引
    fileListIndexArr: [],
    // 问题列表
    questionListObj: {},
    // 文件对象数组
    fileObjArr: [],
    // 工单模块
    workSearchListArr: [],
    // 第一次的工单分类
    classArrList: [],
    // 站点地址
    siteUrlData: ''
  },
  mutations: {
    SET_NAV_MENU: function SET_NAV_MENU(state, navMenuList) {
      state.navMenuList = navMenuList;
    },
    SET_sysPanelCreateArr: function SET_sysPanelCreateArr(state, sysPanelCreateArr) {
      state.sysPanelCreateArr = sysPanelCreateArr;
    },
    SET_sysPanelListIndexArr: function SET_sysPanelListIndexArr(state, sysPanelListIndexArr) {
      state.sysPanelListIndexArr = sysPanelListIndexArr;
    },
    SET_questionListObj: function SET_questionListObj(state, questionListObj) {
      state.questionListObj = questionListObj;
    },
    SET_fileListIndexArr: function SET_fileListIndexArr(state, fileListIndexArr) {
      state.fileListIndexArr = fileListIndexArr;
    },
    SET_fileObjArr: function SET_fileObjArr(state, fileObjArr) {
      state.fileObjArr = fileObjArr;
    },
    SET_workSearchListArr: function SET_workSearchListArr(state, workSearchListArr) {
      state.workSearchListArr = workSearchListArr;
    },
    SET_classArrList: function SET_classArrList(state, classArrList) {
      state.classArrList = classArrList;
    },
    SET_SITE_DATA: function SET_SITE_DATA(state, siteUrlData) {
      state.siteUrlData = siteUrlData;
    }
  },
  actions: {
    // 获取站点
    GetSiteAdmin: function GetSiteAdmin(_ref) {
      var commit = _ref.commit,
        state = _ref.state;
      return new Promise(function (resolve, reject) {
        _siteAdmin.default.different({
          site_url: window.location.host,
          websites: JSON.parse(localStorage.getItem('websites'))
        }).then(function (res) {
          if (res.code !== 200) {
            return _elementUi.Message.error(res.msg);
          }
          localStorage.setItem('siteId', res.data.site_url ? res.data.site_url.id : 1);
          localStorage.setItem('permissions_ids', res.data.permissions ? res.data.permissions.join(',') : '');
          if (res.data.site_url) {
            localStorage.setItem('site_name', res.data.site_url.site_name.toLowerCase());
          } else {
            localStorage.setItem('site_name', '');
            if (window.location.hostname === 'localhost') {
              localStorage.setItem('site_name', 'mx');
            }
            var lang = localStorage.getItem('language') || 'zh';
            var i18n = new _vueI18n.default();
            // console.log(i18n.t('userNotInSite'), i18n.tc('userNotInSite'), ',.--')

            (0, _elementUi.Message)({
              message: lang === 'zh' ? '用户不在此站点中' : lang === 'en' ? 'User is not in this site' : 'người dùng không có trong trang web này',
              type: 'error',
              duration: '1500'
            });
            if (window.location.hostname !== 'localhost') {
              // 清除并退出
              setTimeout(function () {
                _store.default.dispatch('LogOut').then(function () {
                  location.reload();
                });
              }, 1800);
            }
          }
          resolve(res);
        }).catch(function (e) {
          reject(e);
        });
      });
    },
    // 获取导航菜单列表
    GetNavMenuList: function GetNavMenuList(_ref2) {
      var commit = _ref2.commit;
      return new Promise(function (resolve, reject) {
        _WorkOrderQues.default.getPanelList().then(function (res) {
          if (res.code === 200) {
            var data = res.data.get;
            commit('SET_sysPanelCreateArr', res.data.create);
            var lang = localStorage.getItem('language') || 'zh';
            var collect = [{
              config_name: lang === 'zh' ? '汇总' : lang === 'en' ? 'Summary' : 'Tổng',
              id: 999
            }];
            var sysPanelList = [];
            if (data.length) {
              sysPanelList = [].concat((0, _toConsumableArray2.default)(data), collect);
              commit('SET_NAV_MENU', sysPanelList);
              var objData = {
                sysPanelList: sysPanelList,
                sysPanelCreateArr: res.data.create
              };
              resolve(objData);
            } else {
              sysPanelList = [].concat(collect);
              commit('SET_NAV_MENU', sysPanelList);
              var _objData = {
                sysPanelList: sysPanelList,
                sysPanelCreateArr: res.data.create
              };
              resolve(_objData);
            }
          } else {
            commit('SET_sysPanelCreateArr', []);
            commit('SET_NAV_MENU', []);
          }
        }).catch(function (e) {
          reject(e);
        });
      });
    },
    // 获取问题列表
    QuestionList: function QuestionList(_ref3, sysPanelListIndex) {
      var commit = _ref3.commit,
        state = _ref3.state;
      return new Promise(function (resolve) {
        var indexArr = state.sysPanelListIndexArr;
        if (indexArr.includes(sysPanelListIndex)) {
          resolve(state.questionListObj[sysPanelListIndex]);
        } else {
          indexArr.push(sysPanelListIndex);
          commit('SET_sysPanelListIndexArr', indexArr);
          // 调用接口
          _WorkOrderQues.default.getList({
            config_id: sysPanelListIndex,
            page: 1,
            row: 999
          }).then(function (quesRes) {
            var questionList = [];
            var code = quesRes.code,
              data = quesRes.data;
            if (code === 200) {
              questionList = (0, _toConsumableArray2.default)(data.get).map(function (e) {
                return {
                  label: "".concat(e.type_name, " > ").concat(e.question_name),
                  value: e.id,
                  classId: e.type_id
                };
              });
            }
            var questionListObj = state.questionListObj;
            questionListObj[sysPanelListIndex] = questionList;
            commit('SET_questionListObj', questionListObj);
            resolve(questionList);
          });
        }
      });
    },
    // 获取文件数据列表
    getFieldAttrList: function getFieldAttrList(_ref4, sysNum) {
      var commit = _ref4.commit,
        state = _ref4.state;
      return new Promise(function (resolve) {
        var fileIndexArr = state.fileListIndexArr;
        if (fileIndexArr.includes(sysNum)) {
          var fileObj = {};
          var fileObjArr = state.fileObjArr;
          fileObjArr.map(function (item) {
            if (item.sysNum === sysNum) {
              fileObj = item;
              return;
            }
          });
          resolve(fileObj);
        } else {
          fileIndexArr.push(sysNum);
          commit('SET_fileListIndexArr', fileIndexArr);
          // 调用接口
          _WorkOrderEntity.default.fetchFieldAttr({
            id: sysNum
          }).then(function (res) {
            var code = res.code,
              msg = res.msg,
              data = res.data;
            var obj = {
              sysNum: sysNum
            };
            if (code === 200) {
              obj.ifBool = true;
              obj.attrTable = data;
            } else {
              obj.ifBool = false;
              obj.attrTable = {};
              _elementUi.Message.error(msg);
            }
            var fileObjArr = state.fileObjArr;
            fileObjArr.push(obj);
            commit('SET_fileObjArr', fileObjArr);
            resolve(obj);
          });
        }
      });
    },
    // 获取工单模块搜索列表
    getWorkSearchList: function getWorkSearchList(_ref5) {
      var commit = _ref5.commit;
      return new Promise(function (resolve, reject) {
        _WorkOrderEntity.default.getWorkSearch().then(function (search) {
          var code = search.code,
            data = search.data;
          if (code === 200) {
            commit('SET_workSearchListArr', data);
          } else {
            commit('SET_workSearchListArr', []);
          }
          resolve(search);
        });
      });
    },
    // 获取工单模块分类
    GetClassArrList: function GetClassArrList(_ref6, params) {
      var commit = _ref6.commit;
      return new Promise(function (resolve, reject) {
        _WorkOrderEntity.default.getWorkClassify(params).then(function (Issue) {
          var code = Issue.code,
            data = Issue.data;
          if (code === 200) {
            commit('SET_classArrList', data);
          } else {
            commit('SET_classArrList', []);
          }
          resolve(Issue);
        });
      });
    },
    // 获取用户列表
    getAccountAll: function getAccountAll(_ref7, params) {
      var commit = _ref7.commit;
      return new Promise(function (resolve, reject) {
        (0, _utility.getAccountList)(params).then(function (rv) {
          resolve(rv);
        });
      });
    }
  }
};
var _default = exports.default = activity;