var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "700px",
        title: _vm.title,
        visible: _vm.innerVisible,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.innerVisible = $event
        },
        close: _vm.innerCancel,
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                rules: _vm.rules,
                "label-position": "right",
                "label-width": "190px",
                model: _vm.form,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("monitoringTeam"),
                    prop: "treatment_group",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: _vm.$t("pleaseSelect") },
                      model: {
                        value: _vm.form.treatment_group,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "treatment_group", $$v)
                        },
                        expression: "form.treatment_group",
                      },
                    },
                    _vm._l(_vm.groupList, function (item, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: {
                          label: item.group_name,
                          value: item.group_name,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("dealDocument"),
                    prop: "handle_class_id",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: _vm.$t("pleaseSelect") },
                      model: {
                        value: _vm.form.handle_class_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "handle_class_id", $$v)
                        },
                        expression: "form.handle_class_id",
                      },
                    },
                    _vm._l(_vm.dealDocument, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.group_name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("publicRemark") } },
                [
                  _c("el-input", {
                    staticClass: "filed-textarea",
                    attrs: {
                      type: "textarea",
                      placeholder: _vm.$t("pleaseEnter"),
                      maxlength: 5000,
                      "show-word-limit": "",
                      clearable: "",
                    },
                    model: {
                      value: _vm.form.public_mark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "public_mark", $$v)
                      },
                      expression: "form.public_mark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("privateRemark") } },
                [
                  _c("el-input", {
                    staticClass: "filed-textarea",
                    attrs: {
                      type: "textarea",
                      placeholder: _vm.$t("pleaseEnter"),
                      maxlength: 5000,
                      "show-word-limit": "",
                      clearable: "",
                    },
                    model: {
                      value: _vm.form.private_mark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "private_mark", $$v)
                      },
                      expression: "form.private_mark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("noteImage") + ":" } },
                [
                  _c(
                    "div",
                    { staticClass: "myUpload" },
                    [
                      _c(
                        "el-upload",
                        {
                          attrs: {
                            action: _vm.uploadUtil.apiUrl,
                            name: "file",
                            "list-type": "picture-card",
                            data: _vm.uploadData,
                            "on-preview": _vm.handlePictureCardPreview,
                            "on-remove": _vm.handleRemove,
                            "on-success": _vm.handleSuccess,
                            limit: 10,
                            "before-upload": _vm.beforeAvatarUpload,
                            "on-exceed": _vm.handleExceed,
                            multiple: "",
                            "file-list": _vm.imageList,
                          },
                          on: {
                            "update:fileList": function ($event) {
                              _vm.imageList = $event
                            },
                            "update:file-list": function ($event) {
                              _vm.imageList = $event
                            },
                          },
                        },
                        [_c("i", { staticClass: "el-icon-plus" })]
                      ),
                      _c(
                        "el-upload",
                        {
                          staticClass: "upload-demo",
                          attrs: {
                            drag: "",
                            action: _vm.uploadUtil.apiUrl,
                            name: "file",
                            data: _vm.uploadData,
                            limit: 10,
                            "before-upload": _vm.beforeAvatarUpload,
                            multiple: "",
                            "on-exceed": _vm.handleExceed,
                            "on-remove": _vm.handleRemove,
                            "on-success": _vm.handleSuccess,
                            "file-list": _vm.imageList,
                          },
                          on: {
                            "update:fileList": function ($event) {
                              _vm.imageList = $event
                            },
                            "update:file-list": function ($event) {
                              _vm.imageList = $event
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "el-icon-upload" }),
                          _c("div", { staticClass: "el-upload__text" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("dragFilesHereOrClickUpload"))
                            ),
                            _c("em", [
                              _vm._v(" " + _vm._s(_vm.$t("clickUpload"))),
                            ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
          }),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.innerCancel } }, [
            _vm._v("\n      " + _vm._s(_vm.$t("cancel")) + "\n    "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.btnLoading },
              on: { click: _vm.innerConfirm },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("confirm")) + "\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }